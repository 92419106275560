<template>
  <div>
    <b-card title="Ödeme Özeti">
      <customer-statistic />
    </b-card>
    <customer-history-details :id-customers="customerId" />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import CustomerStatistic from '@/views/Customers/View/History/Statistic.vue'
import CustomerHistoryDetails from '@/views/Customers/View/History/Details.vue'

export default {
  name: 'CustomerHistory',
  components: {
    CustomerHistoryDetails,
    CustomerStatistic,
    BCard,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (this.customerId) {
        this.$store.dispatch('customerHistory/getDataList', { id_customers: this.customerId })
      }
    },
  },
}
</script>

<style scoped>

</style>

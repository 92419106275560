<template>
  <div>
    <b-row>
      <b-col>
        <statistic-card
          :statistic="counters.debit | toCurrency"
          icon="ClockIcon"
          statistic-title="Borç"
          color="warning"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.payment | toCurrency"
          icon="CheckCircleIcon"
          statistic-title="Ödeme"
          color="success"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.amount | toCurrency"
          icon="BriefcaseIcon"
          statistic-title="Güncel Bakiye"
          color="info"
          prefix="₺"
        />
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Cari Hareketler
        </b-card-title>
      </b-card-header>
      <b-table
        v-if="dataList.length > 0"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(created)="data">
          {{ moment(data.item.created).format('DD.MM.YYYY') }}
          <div v-if="data.item.payment_collections">
            <small class="text-warning">
              <b>Ödeme Tarihi: </b><span class="text-body">{{ moment(data.item.pdate).format('DD.MM.YYYY') }}</span>
            </small>
            <div>
              <small class="text-success">Ödeme Takvimi Aktif.</small>
            </div>
          </div>
        </template>
        <template #cell(content)="data">
          {{ data.item.content }}
        </template>
        <template #cell(amount)="data">
          <div v-if="data.item.amount > '0'">
            <span class="text-success font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div v-else-if="data.item.amount < '0'">
            <span class="text-danger font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div v-else>
            <span class=" font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
          </div>
          <div>
            <small class="text-muted">{{ data.item.payment_collection_status }}</small>
          </div>
          <div>
            <small
              v-if="data.item.customer_user"
              class="text-primary"
            ><strong>Firma Kullanıcısı: </strong>{{ data.item.customer_user }}</small>
            <small
              v-else-if="data.item.username"
              class="text-muted"
            >{{ data.item.username }}</small>
          </div>
          <div v-if="data.item.id_payments">
            <b-badge variant="success">
              <feather-icon
                icon="CreditCardIcon"
                class="mr-25"
              />
              <span>Online Ödeme</span>
            </b-badge>
          </div>
        </template>
      </b-table>
      <b-alert
        v-else
        variant="info"
        show
        class="ml-2 mr-2"
      >
        <div class="alert-body text-center">
          <div>
            <FeatherIcon
              icon="InfoIcon"
              size="16"
            />
          </div>
          <p class="lead">
            İşlem Kaydı bulunmuyor.
          </p>
        </div>
      </b-alert>
      <b-card-footer>
        <b-pagination
          v-if="dataList.length > 0"
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert, BBadge, BCardFooter, BPagination, BTable, BCard,
  BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import StatisticCard from '@/views/CurrentAccounts/Components/StatisticCard.vue'

const tableName = 'current_account_lines'

export default {
  name: 'HistoryCurrentAccounts',
  components: {
    BRow,
    BCol,
    StatisticCard,
    BPagination,
    BTable,
    BCardFooter,
    BBadge,
    BAlert,
    BCard,
    BCardTitle,
    BCardHeader,

  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'created',
          label: 'İŞLEM TARİHİ',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'content',
          label: 'AÇIKLAMA',
        },
        {
          key: 'amount',
          label: 'TUTAR',
          thClass: 'width-100 text-right text-nowrap',
          tdClass: 'width-100 text-right text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.content AS content`,
          `${tableName}.amount AS amount`,
          `${tableName}.pdate AS pdate`,
          `${tableName}.id_payments AS id_payments`,
          'users.name AS username',
          'customer_users.name AS customer_user',
          'payment_collections.id AS payment_collections',
          'payment_collection_status.title AS payment_collection_status',
        ],
        where: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['currentAccountLines/dataList']
    },
    dataCount() {
      return this.$store.getters['currentAccountLines/dataCounts']
    },
    counters() {
      return this.$store.getters['currentAccountLines/counters']
    },
    currentAccounts() {
      return this.$store.getters['customerHistory/currentAccounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.$store.state.app.perPage
      this.pagination(page)
    },
    currentAccounts() {
      this.getDataList()
    },
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      if (this.currentAccounts) {
        this.dataQuery.where['current_account_lines.id_current_accounts'] = this.currentAccounts.id
        this.$store.dispatch('currentAccountLines/getDataList', this.dataQuery)
      }
    },
  },
}
</script>

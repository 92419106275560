<template>
  <b-tabs
    fill
    class="mt-2"
  >
    <b-tab>
      <template #title>
        <feather-icon icon="CreditCardIcon" />
        <span>Ödemeler</span>
      </template>
      <b-row v-if="counters.payments || counters.latePayment || counters.pending">
        <b-col>
          <history-latest-payments />
        </b-col>
        <b-col>
          <history-late-payments />
        </b-col>
        <b-col>
          <history-pending-payments />
        </b-col>
      </b-row>
      <b-alert
        v-else
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Müşteriye ait kayıt bulunmuyor.
        </div>
      </b-alert>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="CalendarIcon" />
        <span>Ödeme Takvimi</span>
      </template>
      <history-payment-collections :id-customers="idCustomers" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BookIcon" />
        <span>Cari Hesap</span>
      </template>
      <history-current-accounts />
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTabs, BTab, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import HistoryLatePayments from '@/views/Customers/View/History/LatePayments.vue'
import HistoryLatestPayments from '@/views/Customers/View/History/LatestPayments.vue'
import HistoryPendingPayments from '@/views/Customers/View/History/PendingPayments.vue'
import HistoryPaymentCollections from '@/views/Customers/View/History/PaymentCollections.vue'
import HistoryCurrentAccounts from '@/views/Customers/View/History/CurrentAccounts.vue'

export default {
  name: 'CustomerHistoryDetails',
  components: {
    HistoryCurrentAccounts,
    HistoryPaymentCollections,
    BAlert,
    HistoryPendingPayments,
    HistoryLatestPayments,
    HistoryLatePayments,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  props: {
    idCustomers: {
      type: String,
      required: true,
    },
  },
  computed: {
    counters() {
      return this.$store.getters['customerHistory/counters']
    },
    dataList() {
      return this.$store.getters['customerHistory/dataList']
    },
  },
}
</script>

<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span>Adres Bilgileri</span>
        </template>
        <customer-address />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Kullanıcılar</span>
        </template>
        <customer-users />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import CustomerInfo from '@/views/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Customers/View/CustomerInvoice.vue'
import CustomerAddress from '@/views/Customers/View/CustomerAddress.vue'
import CustomerUsers from '@/views/Customers/View/CustomerUsers.vue'
import CustomerHistory from '@/views/Customers/View/CustomerHistory.vue'

export default {
  name: 'CustomerView',
  components: {
    BTabs,
    BTab,
    CustomerInfo,
    CustomerInvoice,
    CustomerAddress,
    CustomerUsers,
    CustomerHistory,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
    },
  },
}
</script>

<template>
  <b-card>
    <b-list-group class="mb-1">
      <list-item
        title="Adres Tanımı"
        :value="address.title"
      />
      <list-item
        title="İlgili Kişi"
        :value="address.name"
      />
      <list-item
        title="Telefon"
        :value="address.phone_region_code ? '+' + address.phone_region_code + ' ' + address.phone : address.phone"
      />
      <list-item
        title="E-Posta"
        :value="address.email"
      />
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Adres
        </div>
        <div class="font-weight-light">
          {{ address.address }}
          <div>
            {{ address.city }} {{ address.country }}
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <hr>
    <div class="d-flex justify-content-between">
      <b-button
        v-b-toggle.address-sidebar
        variant="primary"
        size="sm"
        @click="updateItem(address.id)"
      >
        <FeatherIcon icon="EditIcon" />
        Güncelle
      </b-button>
      <b-button
        variant="outline-danger"
        size="sm"
        @click="removeAddress(address.id)"
      >
        <FeatherIcon icon="XIcon" />
        Sil
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BListGroup, BListGroupItem, VBToggle,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/View/ListItem.vue'

export default {
  name: 'AddressCard',
  components: {
    ListItem,
    BCard,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isUpdate: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAddresses/dataItem']
    },
  },
  methods: {
    updateItem(id) {
      this.$store.dispatch('customerAddresses/getDataItem', id)
    },
    removeAddress(id) {
      this.$store.dispatch('customerAddresses/removeData', id)
    },
  },
}
</script>

<template>
  <b-row>
    <b-col>
      <item-statistics
        title="Vadesi Geçmiş Ödemeler"
        icon="XCircleIcon"
        color="light-danger"
        :value="counters.latePayment"
      />
    </b-col>
    <b-col>
      <item-statistics
        title="Vadesi Gelmemiş Ödemeler"
        icon="ClockIcon"
        color="light-info"
        :value="counters.pending"
      />
    </b-col>
    <b-col>
      <item-statistics
        title="Tamamlanmış Ödemeler"
        icon="CheckCircleIcon"
        color="light-success"
        :value="counters.payments"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Customers/View/History/ItemStatistics.vue'

export default {
  name: 'CustomerStatistic',
  components: {
    BRow,
    BCol,
    ItemStatistics,
  },
  computed: {
    counters() {
      return this.$store.getters['customerHistory/counters']
    },
  },
}
</script>

<template>
  <validation-observer ref="simpleRules">
    <title-name />
    <name />
    <phone />
    <email />
    <address-area />
    <country />
    <city />
    <b-button
      variant="primary"
      block
      @click="submitForm"
    >
      <FeatherIcon icon="SaveIcon" /> Kaydet
    </b-button>
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton } from 'bootstrap-vue'
import TitleName from '@/views/Customers/View/CustomerAddress/elements/Title.vue'
import Name from '@/views/Customers/View/CustomerAddress/elements/Name.vue'
import Phone from '@/views/Customers/View/CustomerAddress/elements/Phone.vue'
import Email from '@/views/Customers/View/CustomerAddress/elements/Email.vue'
import AddressArea from '@/views/Customers/View/CustomerAddress/elements/Address.vue'
import Country from '@/views/Customers/View/CustomerAddress/elements/Country.vue'
import City from '@/views/Customers/View/CustomerAddress/elements/City.vue'

export default {
  name: 'Add',
  components: {
    BButton,
    TitleName,
    Name,
    Phone,
    Email,
    AddressArea,
    Country,
    City,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAddresses/dataItem']
    },
    saveData() {
      return this.$store.getters['customerAddresses/dataSaveStatus']
    },
    dataSideBar() {
      return this.$store.getters['customerAddresses/dataSideBar']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataSideBar.status = false
        this.$store.commit('customerAddresses/RESET_DATA_ITEM')
        this.$refs.simpleRules.reset()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customerAddresses/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ödeme Takvimi</b-card-title>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(customers)="data">
            <div>
              <small class="text-primary">{{ data.item.tnumber }}</small>
            </div>
            <template v-if="userData.id_customers !== data.item.id_customers">
              {{ data.item.customers }}
            </template>
            <div>
              <small class="text-muted">{{ data.item.title }}</small>
            </div>
          </template>
          <template #cell(payment_collection_status)="data">
            <div class="text-left">
              {{ data.item.payment_collection_status }}
              <template v-if="data.item.id_payment_collection_status !== '3'">
                <div v-if="(data.item.alert1 || data.item.alert3 || data.item.alert5)">
                  <small class="text-success">
                    <feather-icon icon="BellIcon" />
                    Hatırlatma Açık
                  </small>
                </div>
                <div v-else>
                  <small class="text-danger">
                    <feather-icon icon="BellOffIcon" />
                    Hatırlatma Kapalı
                  </small>
                </div>
              </template>
            </div>
          </template>
          <template #cell(amount)="data">
            <div class="text-right">
              <div v-if="data.item.partial_amount && data.item.amount !== data.item.partial_amount">
                <del>
                  <div class="text-muted">
                    {{ data.item.amount | toCurrency }} TL
                  </div>
                </del>
                <div>
                  {{ data.item.amount - data.item.partial_amount | toCurrency }} TL
                </div>
                <div>
                  <small class="text-success">{{ data.item.partial_amount | toCurrency }} TL Ödendi</small>
                </div>
              </div>
              <div v-else>
                {{ data.item.amount | toCurrency }} TL
              </div>
              <div v-if="data.item.partial === '1'">
                <small class="text-primary">Kısmi Ödeme Yapılabilir</small>
              </div>
            </div>
          </template>
          <template #cell(pdate)="data">
            <div class="text-left">
              <div class="text-warning">
                {{ moment(data.item.pdate).format('DD.MM.YYYY') }}
              </div>
              <div>
                <template v-if="moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') >= 1">
                  <small class="text-muted">{{ moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') }} gün kaldı.</small>
                </template>
                <template v-else-if="moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days') === 0">
                  <small class="text-muted">Bugün</small>
                </template>
                <template v-else>
                  <small class="text-muted">{{ Math.abs(moment(data.item.pdate).diff(moment().format('YYYY-MM-DD'),'days')) }} gün önce.</small>
                </template>
              </div>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <template v-else>
        <b-alert
          variant="info"
          show
          class="ml-2 mr-2"
        >
          <div class="alert-body text-center">
            <div>
              <FeatherIcon
                icon="InfoIcon"
                size="16"
              />
            </div>
            <p class="lead">
              Henüz bir kayıt bulunmuyor.
            </p>
          </div>
        </b-alert>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BCard,
  BCardFooter,
  BCardHeader,
  BCardTitle,
  BPagination,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'HistoryPaymentCollections',
  components: {
    BAlert,
    BCardHeader,
    BPagination,
    BCardTitle,
    BCardFooter,
    BCard,
    BTable,
  },
  props: {
    idCustomers: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'customers',
          label: 'MÜŞTERİ',
        },
        {
          key: 'pdate',
          label: 'Ödeme Günü',
          thClass: 'text-left text-nowrap width-100',
          tdClass: 'text-left text-nowrap width-100',
        },
        {
          key: 'amount',
          label: 'İŞLEM TUTARI',
          thClass: 'text-right text-nowrap width-100',
          tdClass: 'text-right text-nowrap width-100',
        },
        {
          key: 'payment_collection_status',
          label: 'DURUM',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },
      ],
      dataQuery: {
        select: [
          'payment_collections.id AS id',
          'payment_collections.tnumber AS tnumber',
          'payment_collections.title AS title',
          'payment_collections.amount AS amount',
          'payment_collections.partial_amount AS partial_amount',
          'payment_collection_status.title AS payment_collection_status',
          'payment_collections.id_payment_collection_status AS id_payment_collection_status',
          'users.name AS username',
          'customers.company AS customers',
          'payment_collections.pdate AS pdate',
          'payment_collections.alert1 AS alert1',
          'payment_collections.alert3 AS alert3',
          'payment_collections.alert5 AS alert5',
          'payment_collections.partial AS partial',
          'payment_collections.id_customers AS id_customers',
        ],
        where: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['paymentCollections/dataList']
    },
    dataCount() {
      return this.$store.getters['paymentCollections/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.$store.state.app.perPage
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.dataQuery.where['payment_collections.id_customers'] = this.idCustomers
      this.$store.dispatch('paymentCollections/getDataList', this.dataQuery)
    },
  },
}
</script>
